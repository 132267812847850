import { defineComponent, computed } from 'vue';
import { Button } from '@/components/UI';
import useMySchedule from '@/compositions/useMySchedule';
import store from '@/store';
export default defineComponent({
    name: 'ScanTeamButton',
    components: {
        Button
    },
    props: {
        team: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const team = computed(() => props.team);
        return {
            ...useMySchedule(store.state.location.locationId, team)
        };
    },
    methods: {
        async scan() {
            if (this.currentRoomId === null) {
                console.error('no next room selected');
                return;
            }
            if (this.team === null) {
                console.error('no team selected');
                return;
            }
            const rfid = this.team.members.map(member => member.rfid).find(rfid => rfid !== null);
            if (rfid === undefined) {
                console.error('no rfid found');
                return;
            }
            await store.dispatch.rooms.setRFID({ roomid: this.currentRoomId, rfid: 'start', value: rfid });
        }
    }
});
