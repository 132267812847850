import TeamsTable from '@/components/Teams/TeamsTable';
import { defineComponent } from 'vue';
import { useAllTeams, useArchivedTeams } from '@/compositions/useTeams';
import TeamNewButton from '@/components/Teams/TeamNewButton';
export default defineComponent({
    name: 'TeamsTab',
    components: {
        TeamsTable,
        TeamNewButton
    },
    setup: () => ({
        ...useAllTeams(),
        ...useArchivedTeams()
    }),
    computed: {
        waitingCount() {
            return Object.values(this.waitingTeams).length;
        },
        activeCount() {
            return Object.values(this.activeTeams).length;
        },
        finishedCount() {
            return Object.values(this.finishedTeams).length;
        },
        archivedCount() {
            return Object.values(this.archivedTeams).length;
        }
    }
});
