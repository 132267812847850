import './TeamQrCode.scss';
import { defineComponent } from 'vue';
import QRCodeVue3 from 'qrcode-vue3';
export default defineComponent({
    name: 'TeamQrCode',
    components: {
        QRCodeVue3
    },
    props: {
        team: {
            type: Object,
            required: true
        }
    },
    computed: {
        teamUrl() {
            return `${window.location.origin}/MyBeatout?pin=${this.team.pin}`;
        }
    }
});
